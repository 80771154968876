import { Link } from "react-router-dom";
import PageFooter from "../PageFooter.js";
import { useEffect, useState } from "react";
import { putReq, getReq } from "../../configs/ApiUrl.js";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import PageHeader from "../PageHeader.js";
import PageSubheader from "../PageSubheader.js";

function UserContact() {
  const { t, i18n } = useTranslation(["userProfile", "forms", "altText"]);

  // State for user data, loading, and subadmin role
  const [formData, setFormData] = useState({
    email: "",
    username: "",
    phone: "",
    street: "",
    city: "",
    postalCode: "",
    state: "",
    userId: "",
    companyName: "",
  });
  const [loading, setLoading] = useState(false);
  const [isSubAdmin, setIsSubAdmin] = useState(false);
  const [preferredLanguage, setPreferredLanguage] = useState("en"); // Default language

  // Fetch user profile from the backend
  useEffect(() => {
    const fetchUserProfile = async () => {
      setLoading(true);
      try {
        const response = await getReq("/user/profile");
        if (response.success && response.user) {
          const { email, username, phone, address, _id, companyName, role } = response.user;
          setFormData({
            email,
            username,
            phone: phone || "",
            street: address?.street || "",
            city: address?.city || "",
            postalCode: address?.postalCode || "",
            state: address?.state || "",
            userId: _id,
            companyName: companyName || "",
          });          
          setIsSubAdmin(role === "subadmin");
        } else {
          message.error("Failed to load user profile.");
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
        message.error("Something went wrong while loading user profile.");
      } finally {
        setLoading(false);
      }
    };
    fetchUserProfile();
  }, []);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle language selection
  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setPreferredLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  // Handle form submission for updating user data
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const updatedData = {
        email: formData.email,
        username: formData.username,
        phone: formData.phone,
        address: {
          street: formData.street,
          city: formData.city,
          postalCode: formData.postalCode,
          state: formData.state,
        },
        userId: formData.userId,
        preferredLanguage,
      };
      if (isSubAdmin) {
        updatedData.companyName = formData.companyName;
      }

      const updateResp = await putReq("/user/update", updatedData);

      if (updateResp?.success) {
        message.success("Update successful");
      } else {
        message.error("Update failed");
      }
    } catch (error) {
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center min-h-screen">
      <PageSubheader
        backLink="/user-profile"
        title={t("userProfile:contactInfoButton")}
        description={t("userProfile:contactInfoButton")}
      />
      <div className="bg-white drop-shadow w-full p-6 sm:p-10 max-w-4xl mx-auto">
        <UserForm
          title=""
          fields={[
            {
              label: t("forms:emailLabel"),
              name: "email",
              type: "email",
              colSpan: 2,
            },
            {
              label: t("forms:usernameLabel"),
              name: "username",
              type: "text",
              colSpan: 2,
            },
            isSubAdmin && {
              label: t("forms:companyNameLabel"),
              name: "companyName",
              type: "text",
              colSpan: 2,
            },
            { label: t("forms:phoneLabel"), name: "phone", type: "tel" },
            {
              label: t("forms:streetLabel"),
              name: "street",
              type: "text",
            },
            { label: t("forms:cityLabel"), name: "city", type: "text" },
            {
              label: t("forms:postalLabel"),
              name: "postalCode",
              type: "text",
            },
            {
              label: t("forms:provinceLabel"),
              name: "state",
              type: "text",
            },
            {
              label: t("forms:langPref"),
              name: "preferredLanguage",
              type: "select",
              options: [
                { value: "en", label: "English" },
                { value: "sv", label: "Swedish" },
              ],
            },
          ].filter(Boolean)}
          buttonText={t("forms:profileUpdateButton")}
          formData={formData}
          onChange={handleChange}
          onLanguageChange={handleLanguageChange}
          preferredLanguage={preferredLanguage}
          onSubmit={handleSubmit}
          loading={loading}
        />
      </div>
    </div>
  );
}

// UserForm Component for displaying the form
function UserForm({
  title,
  fields,
  buttonText,
  formData,
  onChange,
  onSubmit,
  loading,
  onLanguageChange,
  preferredLanguage,
}) {
  return (
    <form className="mb-8" onSubmit={onSubmit}>
      {title && (
        <div className="mb-4 text-2xl font-semibold">
          <h1>{title}</h1>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {fields.map((field, index) => (
          <div
            key={index}
            className={`flex flex-col mb-4 ${
              field.colSpan ? `md:col-span-${field.colSpan}` : ""
            }`}
          >
            <label
              htmlFor={field.name}
              className="text-zinc-400 font-semibold"
            >
              {field.label}
            </label>
            {field.type === "select" ? (
              <select
                name={field.name}
                id={field.name}
                value={preferredLanguage}
                onChange={onLanguageChange}
                className="form-field w-full"
              >
                {field.options.map((option, idx) => (
                  <option key={idx} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            ) : (
              <input
                name={field.name}
                id={field.name}
                type={field.type}
                autoComplete="none"
                className={`w-full form-field ${
                  field.name === "email"
                    ? "bg-gray-100 text-gray-500 cursor-not-allowed"
                    : ""
                }`}
                value={formData[field.name]}
                onChange={onChange}
                disabled={field.name === "email"}
              />
            )}
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-8">
        <button
          type="submit"
          disabled={loading}
          className={`btn-main w-full flex items-center justify-center mb-12 ${
            loading ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          {loading ? "Loading..." : buttonText}
        </button>
      </div>
    </form>
  );
}

export default UserContact;
